<template>
  <div class="site-wrapper site-page--login">
    <div></div>
    <div class="site-content__wrapper">
      <div class="mid-content1">
        <!-- 左侧透明 -->
        <div class="login-left">
          <div class="img">
            <img src="@/assets/img/login.png" alt />
          </div>
          <div class="en">WELCOME</div>
        </div>
        <!-- 右侧登录 -->
        <div class="login-main">
          <img src="@/assets/img/logo.png" alt style="margin-top:20px" />
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            @keyup.enter.native="dataFormSubmit()"
            status-icon
            style="margin-top:-10px"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="dataForm.userName"
                placeholder="用户账号/手机号"
                style="width:518px;margin-top:20px;height:49px;line-height:49px"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="dataForm.password"
                type="password"
                placeholder="密码"
                prefix-icon="el-icon-lock"
                style="width:518px;height:49px;line-height:49px"
              ></el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-row :gutter="20">
                <el-col :span="14">
                  <el-input
                    class="input_yzm"
                    v-model="dataForm.captcha"
                    placeholder="验证码"
                    style="width:300px;margin-left:57px;margin-top:2px;"
                  ></el-input>
                </el-col>
                <el-col :span="10" class="login-captcha">
                  <img :src="captchaPath" @click="getCaptcha()" alt />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button
                style="width:518px;margin-top:10px;background: #2c8cf0;border-radius: 4px;border-radius: 4px;height:49px"
                class="login-btn-submit"
                type="primary"
                @click="dataFormSubmit()"
              >登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUUID } from "@/utils";
export default {
  data() {
    return {
      dataForm: {
        userName: "",
        password: "",
        uuid: "",
        captcha: ""
      },
      dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        captcha: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      },
      captchaPath: ""
    };
  },
  created() {
    this.getCaptcha();
  },
  methods: {
	  
    // 提交表单
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.$http({
            url: this.$store.state.httpUrl + "/sys/login",
            method: "post",
            data: this.$http.adornData({
              username: this.dataForm.userName,
              password: this.dataForm.password,
              uuid: this.dataForm.uuid,
              captcha: this.dataForm.captcha
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$cookie.set("token", data.token);
              this.$router.replace({ name: "Workbench" });
            } else {
              this.getCaptcha();
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID();
      this.captchaPath = this.$store.state.httpUrl + '/captcha.jpg?uuid=' + this.dataForm.uuid;
    }
  }
};
</script>

<style lang="scss">
//总页面
html,
body {
  width: 100%;
  height: 100%;
}
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .mid_content {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    margin: auto;
  }

  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
	bottom: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }
  .el-input__inner {
    height: 49px;
  }
  .login-left {
    width: 457px;
    height: 597px;
    background: rgba(13, 135, 200, 0.73);
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-115%, -50%);
  }
  .login-main {
    width: 639px;
    height: 597px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-14%, -50%);

    text-align: center;
    .el-form-item__error {
      left: 70px;
    }
  }
  .login-title {
    font-size: 2rem;
    color: #2c8cf0;
    font-style: normal;
  }
  .login-captcha {
    overflow: hidden;
    > img {
      width: 180px;
      height: 49px;
      margin-right: 3rem;
      cursor: pointer;
      margin-top: 2px;
    }
  }
  //按钮
  .login-btn-submit {
    width: 60%;
    background-color: #426579;
    margin-top: 0.3rem;
    border: #426579;
  }
}
.en {
  color: #fff;
  font-family: PingFangSC-Light;
  font-size: 22px;
  margin-top: 126px;
  font-weight: 100;
}
.img {
  margin-top: 220px;
}
</style>